"use client";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

const AuthLinks = () => {
  const pathnme = usePathname();

  const linkClassName = clsx(
    "underline underline-offset-2 hover:opacity-75 transition ease-out"
  );

  return (
    <div className={clsx("space-x-4 pt-4 font-body text-sm text-zinc-300")}>
      {pathnme !== "/signup" && (
        <Link href="/signup" className={clsx(linkClassName)}>
          Create an account
        </Link>
      )}
      {pathnme !== "/forgotten" && (
        <Link href="/forgotten" className={clsx(linkClassName)}>
          Forgotten your password?
        </Link>
      )}
      {pathnme !== "/signin" && (
        <Link href="/signin" className={clsx(linkClassName)}>
          Log In
        </Link>
      )}
    </div>
  );
};

export default AuthLinks;
